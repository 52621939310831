@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  border-radius: map.get($radiuses, '4');
  overflow: hidden;
  position: relative;
}

.wistia {
  > * {
    padding-top: 56.26%;
    position: relative;

    > * {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      > * {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.youtube {
  padding-top: 56.26%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
