.embed {
  padding-top: 56.26%;
  position: relative;

  iframe {
    border: 0;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}